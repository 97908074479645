@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./Montserrat-VariableFont_wght.ttf) format(tff);
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url(./Poppins-Regular.ttf) format(tff);
}
@font-face {
  font-family: "Poppins-semiBold";
  src: local("Poppins-SemiBold"), url(./Poppins-SemiBold.ttf) format(tff);
}
